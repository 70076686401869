import React from "react"
import { graphql } from "gatsby"
import image404 from "../images/404.jpg"
import TSeo from "gatsby-component-lib/components-legacy/technical/t-seo"
import OLayout from "gatsby-component-lib/components-legacy/organisms/o-layout"
import ALink from "../../../../gatsby-component-lib/components-legacy/atoms/a-link"

export const query = graphql`
  query {
    tree: allTypo3Page(
      filter: { type: { eq: "navigation" }, slug: { eq: "left" } }
    ) {
      edges {
        node {
          children {
            id
          }
          content
          website
          slug
        }
      }
    }
    footer: allTypo3Page(filter: { type: { eq: "footer_page" } }) {
      edges {
        node {
          content
        }
      }
    }
  }
`

const NotFoundPage = ({ data, pageContext }) => {
  const tree = JSON.parse(data.tree.edges[0].node.content).tree
  const footer = data.footer.edges[0]
    ? JSON.parse(data.footer.edges[0].node.content)
    : null
  return (
    <OLayout
      tree={tree}
      website={pageContext.website}
      website_lang={pageContext.website_lang}
      footerPageNodeContent={footer}
    >
      <TSeo
        title="404"
        metadata=""
        website={pageContext.website}
        website_theme={pageContext.website_theme}
      />
      <main className="ef-content ef-content--404">
        <div className="ef-404">
          <div
            className="ef-404__backdrop"
            style={{
              backgroundImage: "url(" + image404 + ")",
            }}
          />

          <div className="ef-404__content">
            <div className="ef-container ef-container--l">
              <h1 className="ef-heading ef-heading--1 ef-404__heading">
                404 – Seite nicht gefunden
              </h1>
              <p className="ef-paragraph ef-paragraph--lead">
                Wir bauen noch, und sind auf einem guten Weg, auch wenn einige
                Pfade ins Leere führen. Vielleicht hast du dich aber nur
                verlaufen. Dann helfen wir dir gerne zurück.{" "}
              </p>
              <div className="ef-buttons">
                <ALink
                  className="ef-button ef-button--primary ef-button--large"
                  link="/"
                >
                  Startseite
                </ALink>
                <ALink
                  className="ef-button ef-button--primary ef-button--large"
                  link="https://shop.eintracht.de"
                >
                  Fanshop
                </ALink>
                <ALink
                  className="ef-button ef-button--primary ef-button--large"
                  link="https://tickets.eintracht.de"
                >
                  Tickets
                </ALink>
              </div>
            </div>
          </div>
        </div>
      </main>
    </OLayout>
  )
}

export default NotFoundPage
